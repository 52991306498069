import { getBrandValue } from '~/shared/utils/brandNameHelper';

import { fontStacks } from '../../styles/themes/fabletics/fonts';
import { fontStacks as sxfFontStacks } from '../../styles/themes/savage/fonts';
import { fontStacks as yittyFontStacks } from '../../styles/themes/yitty/fonts';

const fontFamilies = getBrandValue({
  savage: {
    Arizona: sxfFontStacks.headings,
    Plain: sxfFontStacks.baseFamily,
  },
  default: {
    'Assistant (DEPRECATED: DO NOT USE)': fontStacks.baseFamily,
    'GT Pressura Standard': fontStacks.standardGtPressuraFamily,
    'GT Pressura Extended': fontStacks.extendedGtPressuraFamily,
    'Playfair Display': fontStacks.tempFamily,
    'Neue Haas Grotesk Display': yittyFontStacks.baseFamily,
    'Neue Haas Grotesk Text': yittyFontStacks.textFamily,
    GTPressura: fontStacks.standardGtPressuraFamily,
    'GTPressura-Extended': fontStacks.extendedGtPressuraFamily,
    'Playfair-Display (DEPRECATED: DO NOT USE)': fontStacks.tempFamily,
    'Trade-Gothic-Next (DEPRECATED: DO NOT USE)': fontStacks.tempFamily,
    'Trade-Gothic-Next Condensed (DEPRECATED: DO NOT USE)':
      fontStacks.tempFamily,
    'Neue-Haas-Grotesk-Display': yittyFontStacks.baseFamily,
    'Neue-Haas-Grotesk-Text': yittyFontStacks.textFamily,
  },
});

export default fontFamilies;
