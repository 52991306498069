import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextNode from '~/shared/components/TextNode';
import fontFamilies from '~/styles/themes/fontFamilies';

import useBuilderStyle from '../../utils/useBuilderStyle';

const Text = styled.span`
  font-family: ${({ fontFamily }) => fontFamilies[fontFamily]};
`;

const textStyleProperties = [
  'color',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'letterSpacing',
  'lineHeight',
  'textDecoration',
  'textShadow',
];

const BuilderText = ({
  attributes,
  builderBlock,
  builderState,
  fontFamily,
  fontVariant,
  text,
}) => {
  const style = useBuilderStyle({ builderBlock, builderState });
  const textStyle = React.useMemo(
    () =>
      textStyleProperties.reduce(
        (acc, cur) => (style?.[cur] ? { ...acc, [cur]: style?.[cur] } : acc),
        {}
      ),
    [style]
  );

  /**
   *  `fontVariant` is deprecated as a user-facing field in the builder UI, however, builder users
   * can still copy+paste legacy _Text_ components and/or duplicate legacy pages with the legacy
   * _Text_ component being used that contains `fontVariant` in the _Text_ > Data > JSON >
   * component.options.fontVariant = <some fontVariant string>
   *
   * `fontWeight` and `textTransform` must be defined here conditionally dependent on the builder
   * defined `fontSize` for backwards capability. This supports legacy implementation by avoiding
   * page stakeholders from having to update a massive amount of pages in less than a week, only
   * for the component to be deprecated in ~1-1.5 months time.
   *
   * TODO: `fontVariant` is now set to "default" when it exists which sets the variant to a brands
   * default typography variant. The entire conditional below should be deprecated in the future
   * to make `fontVariant` obsolete. This will be handled in FLCX-3132.
   */
  if (fontVariant) {
    const fontWeight = parseInt(textStyle.fontSize) >= 24 ? 700 : 600;
    const fontCase = parseInt(textStyle.fontSize) >= 24 ? 'uppercase' : 'none';
    textStyle.fontWeight = fontWeight;
    textStyle.textTransform = fontCase;

    return (
      <TextNode
        defaultMessage={text}
        variant="default"
        textStyle={textStyle}
        {...attributes}
        data-builder-component-type="text"
      />
    );
  }

  return (
    <Text
      fontFamily={fontFamily}
      {...attributes}
      data-builder-component-type="text"
    >
      {text}
    </Text>
  );
};

BuilderText.propTypes = {
  attributes: PropTypes.object,
  builderBlock: PropTypes.object,
  builderState: PropTypes.object,
  fontFamily: PropTypes.string,
  fontVariant: PropTypes.string,
  text: PropTypes.string,
};

export default BuilderText;
