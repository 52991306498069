import React from 'react';

import PropTypes from 'prop-types';

import Text from '~/shared/components/Text';
import { FormattedMessage } from '~/techstyle-shared/react-intl';

import BuilderLocalizedText from '../BuilderLocalizedText';

const DynamicFormattedMessage = FormattedMessage;

export default function TextNode({
  defaultMessage,
  messageId,
  containerStyle,
  textStyle,
  variant,
  ...rest
}) {
  return (
    <div style={containerStyle} {...rest}>
      <Text as="span" style={textStyle} variant={variant} {...rest}>
        {messageId ? (
          <DynamicFormattedMessage
            defaultMessage={defaultMessage}
            id={messageId}
          />
        ) : (
          <BuilderLocalizedText text={defaultMessage} {...rest} />
        )}
      </Text>
    </div>
  );
}

TextNode.propTypes = {
  containerStyle: PropTypes.object,
  defaultMessage: PropTypes.string,
  messageId: PropTypes.string,
  textStyle: PropTypes.object,
  variant: PropTypes.string,
};
