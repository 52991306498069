import { useMemo } from 'react';

const useBuilderStyle = ({ builderBlock, builderState }) => {
  const responsiveStyles = builderBlock?.responsiveStyles;
  const deviceSize = builderState?.state?.deviceSize;
  const style = useMemo(() => {
    switch (deviceSize) {
      case 'small':
        return (
          responsiveStyles?.small ||
          responsiveStyles?.medium ||
          responsiveStyles?.large
        );
      case 'medium':
        return responsiveStyles?.medium || responsiveStyles?.large;
      case 'large':
      default:
        return responsiveStyles?.large;
    }
  }, [deviceSize, responsiveStyles]);
  return style || {};
};

export default useBuilderStyle;
